<template>
  <div class="peoject__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      项目部管理
    </div>
    <div class="content__wrapper">
      <div class="header">
        <a-button type="primary" icon="plus" @click="visibleProject = true" v-perms="'add-project'">新建项目部</a-button>
      </div>
      <div class="body1">
        <projectItem
          v-for="line in tableData"
          :key="line.id"
          :project-item="line"
          @handleEdit="handleEdit"
          @handleOpenProjectDepartment="handleOpenProjectDepartment"
          @handleOpen="handleOpen"
          @handleOpenUser="handleOpenUser"
        />
      </div>
    </div>
    <a-modal
      :title="`${isEdit}项目`"
      :visible="visible"
      cancelText="取消"
      okText="保存"
      width="500px"
      :confirmLoading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :model="form" ref="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-form-model-item
          label="项目名称"
          :rules="[{ required: true, trigger: 'blur', message: '请输入项目名称' }]"
          prop="projectName"
        >
          <a-input v-model="form.projectName" allowClear placeholder="请输入项目名称" />
        </a-form-model-item>
        <a-form-model-item
          label="项目负责人"
          :rules="[{ required: true, trigger: 'change', message: '请选择项目负责人' }]"
          prop="projectLeaderId"
        >
          <a-select v-model="form.projectLeaderId" mode="multiple" placeholder="请选择项目负责人" allowClear>
            <a-select-option v-for="user in userList" :key="user.userId" :value="user.userId">{{
              user.realName
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="项目起止时间"
          :rules="[{ required: true, trigger: 'blur', message: '请选择项目起止时间' }]"
          prop="projectTime"
        >
          <a-range-picker v-model="form.projectTime" value-format="YYYY-MM-DD" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      :title="`${isEdit}项目部`"
      :visible="visibleProject"
      cancelText="取消"
      okText="保存"
      width="400px"
      :confirmLoading="confirmLoading"
      @ok="handleSubmitProjectDepartment"
      @cancel="handleCancelProject"
    >
      <div class="project_modle">
        <a-form-model :model="projectForm" ref="projectForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
          <a-form-model-item
            label="项目部名称"
            :rules="[{ required: true, trigger: 'blur', message: '请输入项目部名称' }]"
            prop="projectDepartment"
          >
            <a-input v-model="projectForm.projectDepartment" allowClear placeholder="请输入项目部名称" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <userModal ref="userModal"/>
  </div>
</template>

<script>
import {
  employmentUserAll,
  getWorkbenchProject,
  addProject,
  updateProject,
  addProjectDepartment,
  updateProjectDepartment
} from '@/api/workbench'
import moment from 'moment'
import projectItem from './project-item.vue'
import userModal from './user-modal.vue'

export default {
  name: 'Index',
  components: {
    projectItem,
    userModal
  },
  data () {
    return {
      visible: false,
      visibleProject: false,
      loading: false,
      edit: false,
      projectForm: {},
      form: {},
      userList: [],
      tableData: [],
      confirmLoading: false
    }
  },
  computed: {
    isEdit () {
      return this.edit ? '编辑' : '新增'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      employmentUserAll().then((res) => {
        this.userList = res.data
      })
      this.handleGetProjectData()
    },
    handleGetProjectData () {
      this.loading = true
      getWorkbenchProject({
        pageIndex: 1,
        pageSize: 1000
      })
        .then((res) => {
          this.tableData = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCancel () {
      this.$refs.form.resetFields()
      this.form = {}
      this.visible = false
      this.edit = false
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const model = {
            ...this.form,
            projectStartTime: this.form.projectTime[0],
            projectEndTime: this.form.projectTime[1],
            projectLeaderId: this.form.projectLeaderId.join(',')
          }
          const fn = this.edit ? updateProject : addProject
          this.confirmLoading = true
          fn(model)
            .then(() => {
              this.$message.success(`项目${this.isEdit}成功`)
              this.handleGetProjectData()
              this.handleCancel()
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleSubmitProjectDepartment () {
      this.$refs.projectForm.validate((valid) => {
        if (valid) {
          const fn = this.edit ? updateProjectDepartment : addProjectDepartment
          this.confirmLoading = true
          fn(this.projectForm)
            .then((res) => {
              if (res.success) {
                this.$message.success(`项目部${this.isEdit}成功`)
                this.handleGetProjectData()
                this.handleCancelProject()
              } else {
                this.$message.warning(res.errMessage)
              }
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    },

    handleEdit (row) {
      const formatTime = (time) => {
        return time && moment(time).format('YYYY-MM-DD')
      }
      this.form = {
        ...row,
        projectTime: [formatTime(row.projectStartTime), formatTime(row.projectEndTime)],
        projectLeaderId: row.projectLeaderId.split(',')
      }
      this.edit = true
      this.visible = true
    },
    handleCancelProject () {
      this.$refs.projectForm.resetFields()
      this.projectForm = {}
      this.visibleProject = false
      this.edit = false
    },
    handleOpen (line) {
      this.form = {
        projectDepartmentId: line.projectDepartmentId,
        projectLeaderId: []
      }
      this.visible = true
    },
    handleOpenUser (data) {
      this.$refs.userModal.show(data)
    },
    handleOpenProjectDepartment (line) {
      this.visibleProject = true
      this.edit = true
      this.projectForm = { ...line }
    },
    formatTime (row) {
      const fn = (time) => time && moment(time).format('YYYY/MM/DD')
      return `${fn(row.projectStartTime)} - ${fn(row.projectEndTime)}`
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
.peoject__wrapper {
  height: 100%;
  .content__wrapper {
    height: calc(100% - 45px);
    background: #f0f2f5;
  }
}
</style>
<style scoped lang="less">
@import url('./index');
</style>
